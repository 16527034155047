<template>
  <div class="container">
    <nav-bar />
    <div class="header">
      <div class="login-type">
        <div class="type-title">验证码登录</div>
        <img
          src="https://oss.kaoyanvip.cn/attach/file1646723321818.png"
          alt=""
        />
      </div>
      <div class="note">未注册的手机号将自动创建账号</div>

      <div class="login-form">
        <phone-input :needCode="true" />
        <code-input :disCode.sync="disCode" :action="3" />
        <Submit
          ref="submit"
          :disBtn="$checkBtn(form)"
          @submit="toLogin()"
          btnStr="立即登录"
        />
      </div>
      <Clause />
    </div>
    <Footer pageType="index" />
  </div>
</template>

<script>
import NavBar from '@/components/navbar'
import PhoneInput from '@/components/phoneInput'
import CodeInput from '@/components/codeInput'
import Submit from '@/components/submit'
import Footer from '@/components/footer'
import Clause from '@/components/clause';
import { codeLogin,otherLogin} from '@/api/login'
import { startTrackApp } from '@/tongji'
import Cookies from 'js-cookie'
export default {
  name: 'codeLogin',
  components: {
    NavBar,
    PhoneInput,
    CodeInput,
    Submit,
    Footer,
    Clause,
  },
  data() {
    return {
      form: {
        mobile: '',
        code: '',
      },
      disCode: true,
      isClause: false,
    };
  },
  methods: {
    toLogin() {
      codeLogin(this.form).then((res) => {
        this.$loginCb(res.data.access_token)
        this.$refs.submit.loading = false
      }).catch(() => {
        this.$refs.submit.loading = false
      })
    }
  },
  mounted() {
    startTrackApp('快捷登录')
    if( /micromessenger/i.test(navigator.userAgent) ){
      let code = this.$route.query.code
      if(code) {
        Cookies.set('code', code, {
          domain: 'kaoyanvip.cn'
        })
        otherLogin({code: code, provider: 'wechat'})
          .then((res) => {
            this.$loginCb(res.data.access_token)
          }).catch(() => {
            sessionStorage.setItem('code',code)
            this.$router.push('/bind')
          })
      } else {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          process.env.VUE_APP_WX_APPID
        }&redirect_uri=${encodeURIComponent(
          window.location.href
        )}&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect`;
      }
    }
  },
};
</script>
