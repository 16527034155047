<template>
  <van-field
    clearable
    type="number"
    maxlength="6"
    v-model="code"
    @input="onChange"
    placeholder="请输入验证码">
    <template #button>
      <button type="button" :disabled="disCode" class="code" @click="getCode()">{{ codeStr }}</button>
    </template>
  </van-field>
</template>

<script>
import { Toast } from 'vant'
import { sendSMS } from '@/api/login'
export default {
  name: 'Code',
  props: {
    disCode: {
      type: Boolean,
      default: true
    },
    action: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      code: '',
      codeStr: '获取验证码',
      downNum: 60,
      timer: null,
    }
  },
  methods: {
    onChange(e) {
      this.$parent.form.code = e
    },
    // 验证码倒计时判断
    countDown() {
      this.downNum--
      if ( this.downNum ) {
        this.codeStr = this.downNum + '秒再次获取'
      } else {
        this.codeStr = '获取验证码'
        this.downNum = 60
        this.$emit('update:disCode', false)
        clearInterval(this.timer)
      }
    },
    // 点击获取验证码验证
    getCode() {
      if( this.$isMobile(this.$parent.form.mobile) ) {
        sendSMS({ mobile: this.$parent.form.mobile, valid_type: this.action}).then(() => {
          Toast('验证码已发送')
          this.countDown()
          this.$emit('update:disCode', true)
          this.timer = setInterval(() => {
            this.countDown()
          }, 1000)
        })
      }
    },
  }
}
</script>