<template>
  <div>
    <div class="clause">
      注册即代表同意
      <a class="c494" href="https://h5.kaoyanvip.cn/zt/userAgreement/"
        >《研途考研用户协议》</a
      >和<a class="c494" href="https://h5.kaoyanvip.cn/zt/policy/"
        >《隐私政策》</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
