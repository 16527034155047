<template>
  <div>
    <div class="footer" v-if="showFooter">
      <van-divider class="otherWay" v-if="['index', 'pwd'].includes(pageType)"
        >其他方式登录</van-divider
      >
      <div
        class="toSign"
        @click="$router.push('/pwd')"
        v-if="pageType == 'index'"
      >
        <img src="https://oss.kaoyanvip.cn/attach/file1629971296313.png" />
        账密登录
      </div>
      <div class="toSign" @click="$router.push('/')" v-if="pageType == 'pwd'">
        <img src="https://oss.kaoyanvip.cn/attach/file1629970453331.png" />
        短信验证码登录
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    pageType: {
      type: String,
      default: 'index',
    },
  },
  data() {
    return {
      showFooter: true,
    };
  },
  created() {
    let win_h = document.body.clientHeight;
    window.addEventListener('resize', () => {
      this.showFooter = document.body.clientHeight + 15 > win_h;
    });
  },
};
</script>
